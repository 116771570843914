@import 'fonts';
@import 'variables';
@import 'monsterball';


html, body {
  height: 100%;
}

h5, .h6 {
  font-family:$link-font;
}

p {
  font-family: $text-font;
}

.navigation {
  .logo {
    width: 240px;

    @media (min-width: 576px) {
      width: 200px;
    }

    @media (min-width: 1200px) { 
      width: 280px;
    }
  }

  .nav-link {
    @extend %title-text;
    padding-left:.5rem !important;
    padding-right:.5rem !important;

    &:hover{
      color: $monsterball-green !important;
    }
  }
}

.LoaderButton .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear;
}
@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}

.equipment-table {
  font-size: 18px;
  .equipment-item {
    cursor: pointer;

    .empty-circle{
      display:block;
    }
    .checked-circle{
      display:none;
    }

    &.selected {
      .empty-circle{
        display:none;
      }
      .checked-circle{
        display:block;
      }
    }
  }
}

.packing-list {
  &.read-only {
    .equipment-table {
      .equipment-item {
        cursor: auto;
      }
    }
  }
}

.flexible-header {
  display: inline-flex;
  justify-content: space-between;

  .title-info, .header-item {
    flex: 1;
    display: flex;
    justify-content: center;
    align-self: center;

    .btn {
      white-space: nowrap;
    }

    &:first-child, &:last-child {
      flex: 0;
      @media (min-width: 576px) { 
        flex: 1;
      }
    }

    &:first-child { 
      justify-content: flex-start;
      @media (max-width: 768px) { 
        flex-grow: 0;
      }
    }

    &:last-child { 
      justify-content: flex-end;  
      @media (max-width: 768px) { 
        flex-grow: 0;
      }
    }
  }
}

:not(.packing-list-event-title) .flexible-header {
  display:flex;
}

.delete-item, .confirmation-dialog {
  text-align: center;
  padding: 40px;
  background: white;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  
  > h1 {
    margin-top: 0;
  }
  
  > button {
    width: 160px;
    padding: 10px;
    border: 1px solid #fff;
    margin: 10px;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
  }
}

.react-datepicker-wrapper {
  width:100%;
}


.react-tags {
  @extend .form-control;
  position: relative;
  padding: 3px 0 3px 12px;
  cursor: text;

  &.is-focused {
    border-color: #B1B1B1;
  }

  .react-tags__selected {
    display: inline;
  }

  .react-tags__selected-tag {
    display: inline-block;
    box-sizing: border-box;
    margin:3px 6px 6px 0;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    background: #F1F1F1;
    font-size: 14px;
    line-height: inherit;

    &:after {
      content: '\2715';
      color: #AAA;
      margin-left: 8px;
    }

    &:hover, &:focus {
      border-color: #B1B1B1;
    }
  }

  .react-tags__search {
    display: inline-block;
    margin: 3px 0;
    width: 100%;
  }

  @media screen and (min-width: 30em) {
    .react-tags__search {
      position: relative;
    }
  }

  .react-tags__search-input {
    max-width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    font-size: inherit;
    line-height: inherit;
  }

  .react-tags__search-input::-ms-clear {
    display: none;
  }

  .react-tags__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding-right:10px;
    z-index:99;

    ul {
      margin: 4px -1px;
      padding: 0;
      list-style: none;
      background: white;
      border: 1px solid #D1D1D1;
      border-radius: 2px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    }

    li {
      border-bottom: 1px solid #ddd;
      padding: 6px 8px;

      mark {
        text-decoration: underline;
        background: none;
        font-weight: 600;
        padding:0;
      }

      &:hover {
        cursor: pointer;
        background: #eee;
      }

      &.is-active {
        background: #b7cfe0;
      }

      &.is-disabled {
        opacity: 0.5;
        cursor: auto;
      }
    }
  }
}

.category-filter {
  
  .category-filter-item {
    cursor: pointer;
    
    &.selected {
      .badge {
        background-color: $monsterball-green;
      }
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}