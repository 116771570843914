$monsterball-green: #81c53b;
$monsterball-blue: #1c539f;

body {
    background-color: $monsterball-green;
}

.btn {
    @extend %secondary-text;
    color:white !important;
    border-radius: 50px;

    &.btn-primary {
        @extend %title-text;
        color:white !important;
        background-color: rgba($monsterball-green,0.7);
        border-color:$monsterball-green;
        border-width:2px;

        @media (min-width: 1200px) { 
            padding: 0.7em 2.5em;            
        }

        &:hover{
            background-color: $monsterball-green;
        }
    }

    &.btn-secondary {
        background-color: $monsterball-blue;
    }

    &.btn-info {
        background-color: rgba($monsterball-green,0.7);
        border-color:$monsterball-green;
    }

    &.btn-link {
        text-decoration:none;
        &:focus {            
            outline:none;
            box-shadow:none;
        }
    }
}

h1, .h1 {
    color:$monsterball-green;
    font-family:$link-font;
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: 0px;

    a {
        @extend h1;
        &:hover{
            color:$monsterball-green;
        }
    }
}

h5, .h5 {
    @extend h1;
}

form {
    .form-label {
        font-family: $text-font;
        color:#333;
        font-weight: 400;
        font-size: 15px;
        margin-bottom:5px;
        line-height: 1.3;

        .required {
            color: #ec1c24;
        }
    }

    .form-control {
        border: 1px solid $monsterball-green;
        border-radius: 0px;
        color: #000000;
        transition: background-color 0.3s ease-out;
    }    

    .form-text {
        font-family: $text-font;
    }

    h5.form-section, .equipment-title {
        background: $monsterball-blue;
        text-align: center!important;
        text-transform: uppercase;

        color: #fff!important;
        font-size: 17px!important;
        line-height: 31px;
        margin-bottom:10px;
    }

    .react-datepicker-wrapper{
        display:block;
    }

    .btn {
        &.btn-primary {
            border-radius: 3px !important;
            border: 0px !important;
            background-color: $monsterball-green !important;
            color: white !important;
            text-align: center !important;
            transition: background-color 0.3s ease-in;

            &:hover{
                background-color: #6faa33 !important;
            }
        }
    }
}

.equipment-table {
    .card {
        border-radius: 0;
    }
    
    .equipment-category{
        background-color: $monsterball-green !important;
        color:white;
    }

    .equipment-item {
        &.selected {
            background-color: rgba($monsterball-blue, 0.6) !important;
            font-weight:bold;
        }

        &.important {
            color: red;
            font-weight:bold;
        }
    }
}

.packing-list-event {
    .card-title {
        background: $monsterball-green;
        color: white;
        padding: 0.5rem;
        text-align: center;
        vertical-align: middle;
    }
}

.packing-list-group {
    .packinglist-date{
        background-color: $monsterball-green !important;
        color:white;
    }

    .packinglist-transfer-staff {
        background-color: $monsterball-blue !important;
        color:white;        
    }
}

.packing-list {
    h2, .h2 {
        color: $monsterball-blue;
    }

    h4 {
        color: $monsterball-blue;
        font-family:$text-font;
    }

    .packing-list-info {
        dt {
            color: $monsterball-blue;
            font-size:18px;
            font-family:$text-font;
        }
        
        dd{
            font-size: 18px;
            font-family:$text-font;
        }
    }

    .packing-list-comments {
        border: 1px solid black;
        padding:20px;
    }

    .equipment-section {
        background: $monsterball-blue;
        text-align: center!important;
        text-transform: uppercase;

        color: #fff!important;
        font-size: 17px!important;
        line-height: 31px;
        margin-bottom:10px;
    }

    .equipment-title{
        background: $monsterball-green !important;
        color: white !important;
        text-align: center;
        vertical-align: middle;
        h5 {
            color:white;
        }
        td {
            padding: 0.5rem;
        }
    }
}

.transfer-list-vehicles {
    svg {
        color: $monsterball-blue;
    }
}

.dashboard-main {
    &.navbar-brand {
        display: flex;
        align-items: center;
    }
    .dashboard-header {
        @extend h1;
        font-family:$dashboard-font;
        font-size:2rem;
    }
}

.dashboard-links {
    font-family:$dashboard-font;
    font-size:1.5rem;
    text-align:center;
    .link{
        padding:5px;
        border-radius: 8px;
        color:white;
        text-transform: uppercase;
        background-color: rgba($monsterball-green,0.7);
        border: 2px solid $monsterball-green;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 200px;
        transition: background-color 0.3s ease-in;
        &:hover{
            background-color: #6faa33 !important;
            text-decoration:none;
        }
      }
}
