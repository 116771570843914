%title-text {
    color:black !important;
    font-family:$link-font;
    font-size:15px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}
    
%secondary-text {
    @extend %title-text;
    font-size:13px;
}